import * as Sentry from "@sentry/vue";
import { Vue as VueIntegration } from "@sentry/integrations";
import axios from "axios";

const APP_TYPE = "LEGACY";
const ENVIRONMENT_URLS = {
  DEV: "ppa-dev",
  TEST: "ppa-test",
  PROD: "ppa"
};

// Get environment from URL at runtime
const getEnvironment = url => {
  let environment = "UNKNOWN";

  for (const [env, urlPart] of Object.entries(ENVIRONMENT_URLS)) {
    if (url.includes(`${urlPart}.packeta`)) {
      environment = env;
      break;
    }
  }

  return environment;
};

const captureRequestError = error => {
  const response = error.response;
  const request = response.config;

  Sentry.withScope(scope => {
    // Set easily identifiable "request" tag to sentry
    scope.setTag("request", `${request.method.toUpperCase()} /${request.url}`);

    // Send detailed context info about the request
    scope.setContext("Request", {
      url: `${request.baseURL}/${request.url}`,
      method: `${request.method.toUpperCase()}`,
      headers: request.headers,
      data: request.data
    });

    // Send detailed context info about the response
    scope.setContext("Response", {
      status: response.status,
      text: response.statusText,
      headers: response.headers,
      data: response.data
    });

    // Capture the error
    Sentry.captureException(error);
  });

  return Promise.reject(error);
};

export const setupSentryInterceptor = () => {
  axios.interceptors.response.use(
    // On fullfilled
    response => response,
    // On rejected
    captureRequestError
  );
};

export const initSentry = Vue => {
  if (process.env.NODE_ENV !== "production") return;

  const dsn = process.env.VUE_APP_SENTRY_DSN;
  const url = window.location.href;

  Sentry.init({
    dsn,
    integrations: [
      new VueIntegration({ Vue, attachProps: true, logErrors: true })
    ],
    environment: getEnvironment(url)
  });

  Sentry.setTags({
    app_type: APP_TYPE
  });
};

export default Sentry;
